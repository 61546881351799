import router from "next/router";
import Slider, { Settings } from "react-slick";
import { Button } from "components/button";
import TriangleTooltip from "components/triangle-tooltip/triangle-tooltip";
import { NewTextHeadingOne } from "styles/text-heading-one";
import { Div } from "styles/typography";
import { DiscountSchoolCard } from "./discount-school-card";
import { FeaturedSchoolsContainer, SchoolCards } from "./landing-page.style";

export const DiscountedSchools = ({ discountedSchools }) => {
   const settings: Settings = {
      dots: false,
      autoplay: true,
      slidesToShow: 4,
      speed: 1000,
      autoplaySpeed: 4000,
      initialSlide: 0,
      infinite: true,
      responsive: [
         {
            breakpoint: 1000,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               arrows: false,
               centerMode: true,
               centerPadding: "40",
               initialSlide: 3,
            },
         },
         {
            breakpoint: 1500,
            settings: {
               slidesToShow: 4,
               slidesToScroll: 1,
               arrows: true,
            },
         },
         {
            breakpoint: 500 || 400 || 300,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               arrows: false,
            },
         },
      ],
   };

   return (
      <FeaturedSchoolsContainer>
         <Div display="flex" alignItems="center" justifyContent="space-between">
            <div className="flex-area">
               <NewTextHeadingOne>Schools with Best Tuition Deals</NewTextHeadingOne>
               <TriangleTooltip text="These are top schools that are offering tuition discounts exclusively on our platform">
                  <img src="/landing-page/tooltipcircle.png" alt="tooltip" />
               </TriangleTooltip>
            </div>
            <Button
               variant="new_outline"
               size="small"
               onClick={() =>
                  router.push({
                     pathname: "/school/search",
                     query: {
                        hasDiscount: "yes",
                     },
                  })
               }
            >
               See More
            </Button>
         </Div>
         <SchoolCards>
            <Slider draggable {...settings} className="card-slider">
               {discountedSchools?.map((sch) => (
                  <DiscountSchoolCard key={sch.slug} data={sch} />
               ))}
            </Slider>
         </SchoolCards>
      </FeaturedSchoolsContainer>
   );
};
