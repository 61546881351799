import _ from "lodash";
import { GetServerSidePropsContext } from "next";
import { ISchoolAccountForm, IIviteSchoolForm } from "container/school-admin/i-account-form";
import { IUpdateSchoolData } from "container/update-school-form/update-school-form";
import { API, Http } from "services/http/http";
import { paramsObjectToQueryString } from "utils/generic-utils";

export const createSchool = (payload: ISchoolData) => Http.post("/school", payload);
export const featureSchool = (id: string) => Http.patch(`/school/${id}/feature`);
export const verifySchool = (id: string) => Http.patch(`/school/${id}/verify`);
export const updateSchool = (slug: string, payload: IUpdateSchoolData) =>
   Http.put(`/school/${slug}`, payload);

export const likeSchool = (id: string) => Http.post(`/school/${id}/like`);

export const uploadImage = (payload: FormData) => Http.post(`/upload`, payload);
export const getSchoolAdminSchools = () => Http.get(`/user/school`);
export const getAllSchoolSchools = () => Http.get(`/school?page=1&limit=none`);
export const getSingleSchoolInfo = (id) => Http.get(`/school/${id}`);
export const compareSchools = (payload: string) => Http.get(`/school/compare?schools=${payload}`);
// export const fetchSchools = (payload: Record<string, any>) =>
//    Http.get(`/school${paramsObjectToQueryString(_.omit(payload, "token"))}`, {
//       headers: {
//          authorization: payload.token,
//       },
//    });

export const getSchoolApplications = () => Http.get(`/school/application`);

export const getApplications = (payload?: any) =>
   API({
      method: "GET",
      url: `/school/application${paramsObjectToQueryString(payload)}`,
   });

export const getEnquiries = (payload?: any) =>
   API({
      method: "GET",
      url: `/admin/school/enquiry${paramsObjectToQueryString(payload)}`,
   });

export const searchEnquiries = (val: string) => Http.get(`/admin/school/enquiry?search=${val}`);

export const getReviews = (payload?: any) =>
   API({
      method: "GET",
      url: `/admin/school/review${paramsObjectToQueryString(payload)}`,
   });

export const searchSchoolReviews = (searchTerm: string) =>
   Http.get(`/admin/school/review?page=1&search=${searchTerm}`);

export const searchSchools = (searchTerm: string, limit?: any, page?: any) =>
   Http.get(`/school?page=${page}&limit=${limit}&search=${searchTerm}`);

export const searchSchoolsAutosuggest = (searchTerm: string) =>
   Http.get(`/school?page=1&search=${searchTerm}`);

export const newSearchSchoolsAutosuggest = (searchTerm: string) =>
   Http.get(`/school?page=1&name=${searchTerm}`);

export const getAllForeignSchools = (token) =>
   Http.get(`/school/foreign?page=1&limit=none`, {
      headers: {
         authorization: token,
      },
   });

export const getSingleSchool = ({ queryKey }, ctx?: any) =>
   API(
      {
         method: "GET",
         url: `/school/${queryKey[1]}`,
      },
      ctx
   );

export const getSingleSchoolClient = (id) => Http(`/school/${id}`);

export const getSingleSchoolAdmin = (id, ctx?: GetServerSidePropsContext, token?: string) =>
   API(
      {
         method: "GET",
         url: `/school/${id}/fetch-details`,
      },
      ctx,
      token
   );

export const getSingleSchoolAdmin2 = (id) => Http(`/school/${id}/fetch-details`);

export const fetchReviews = (id: string) => Http(`/school/${id}/review`);

export const fetchSchools = (payload: Record<string, any>, ctx?: GetServerSidePropsContext) =>
   API(
      {
         method: "GET",
         url: `/school${paramsObjectToQueryString(payload)}`,
      },
      ctx
   );

export const createSchoolReview = (id: string, payload) =>
   Http.post(`/school/${id}/review`, payload);

export const getBanks = () => Http.get("/paystack-bank");

// export const getSchoolAccount = (id: string) => Http.get(`/school/${id}/account`);

export const createSchoolAccount = (id: string, payload: ISchoolAccountForm) =>
   Http.post(`/school/${id}/account`, payload);

export const inviteSchool = (id: string, payload: IIviteSchoolForm) =>
   Http.post(`/user/schAdmin/${id}`, payload);

export const deleteSchoolAccount = (id: string) => Http.delete(`/school/${id}/account`);

export const searchSchool = (val: string) => Http.get(`/school?search=${val}`);
export const applyToSchool = (id: string, payload) => Http.post(`/school/${id}/apply`, payload);
export const schoolEnquiry = (id: string, payload) => Http.post(`/school/${id}/enquiry`, payload);
export const rateSchool = (id: string, payload) => Http.post(`/school/${id}/rating`, payload);

export const getSchoolAccount = (id: string, ctx?: GetServerSidePropsContext) =>
   API(
      {
         method: "GET",
         url: `/school/${id}/account`,
      },
      ctx
   );

export const getSchoolsStats = (payload: any) =>
   Http.get(`/school-stats${paramsObjectToQueryString(payload)}`);
export const getSchoolAnalytics = (id: string, payload: any) =>
   Http.get(`/analytics/school/${id}/overview${paramsObjectToQueryString(payload)}`);

export const getAdminAnalytics = (payload: any) =>
   Http.get(`/analytics/admin/school/overview${paramsObjectToQueryString(payload)}`);

export const getAdminApplicantAnalytics = (payload: any) =>
   Http.get(`analytics/admin/applicant/overview${paramsObjectToQueryString(payload)}`);

export const fetchAdminSchools = (payload: Record<string, any>, ctx?: GetServerSidePropsContext) =>
   API(
      {
         method: "GET",
         url: `/admin/school${paramsObjectToQueryString(payload)}`,
      },
      ctx
   );

export const getSchoolsStatsTotal = () => Http.get(`/school-stats`);

export const deleteSchool = (id: string) => Http.delete(`/school/${id}`);

export const askAI = (payload) => Http.post(`/ai-assistant`, payload);
