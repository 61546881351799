import React from "react";
import { Rating } from "react-simple-star-rating";
type Props = {
   value: number;
   onChange?: any;
   size?: number;
   readonly: any;
   showToolTip?: boolean | undefined;
};

const tooltipArray = ["Cool", "Interesting", "Good", "Great", "Excellent"];

export function Ratings({ value, onChange, size, readonly, showToolTip }: Props) {
   return (
      <Rating
         showTooltip={showToolTip === false ? false : true}
         tooltipArray={tooltipArray}
         tooltipStyle={{
            fontSize: "10px",
            backgroundColor: "#fff",
            color: "#06152D",
         }}
         readonly={readonly}
         tooltipDefaultText="Please Rate"
         onClick={onChange}
         ratingValue={value}
         size={size || 16}
      />
   );
}
