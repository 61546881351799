import Link from "next/link";
import { useRouter } from "next/router";
import { Arrow } from "container/study-abroad/study-abroad.style";
import { getSession } from "utils/auth";
import { AddSchoolUpdatedContainer } from "./landing-page.style";

export const AddSchoolBanner = ({ numberOfSchools }) => {
   const router = useRouter();
   const { inSession } = getSession();

   function handleSchoolRegistration() {
      if (inSession) {
         router.push("/admin/school/add");
      } else {
         router.push({
            pathname: "/signup",
            query: {
               type: "sch_admin",
            },
         });
      }
   }
   return (
      <AddSchoolUpdatedContainer>
         <div className="text-section">
            <h3>Are you a school administrator or owner?</h3>
            <p>
               List your school to join {numberOfSchools} top private schools on Edusko, It’s
               simple, easy and free!
            </p>
            <Link href={inSession ? "/admin/school/add" : "/signup?type=sch_admin"}>
               <a>
                  <button onClick={handleSchoolRegistration}>
                     <span style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                        Add School
                        <Arrow style={{ marginLeft: "5px" }} />
                     </span>
                  </button>
               </a>
            </Link>
            <Link href="/why-edusko" passHref>
               <a>
                  <div className="why-text">Why top schools use Edusko!</div>
               </a>
            </Link>
         </div>
         <div className="img-div">
            <img src="/images/add-school.png" alt="add-school" />
         </div>
      </AddSchoolUpdatedContainer>
   );
};
