export const CountriesList = [
   {
      name: "Nigeria",
      iso3: "NGA",
      states: [
         {
            name: "Abia",
            state_code: "AB",
         },
         {
            name: "Adamawa",
            state_code: "AD",
         },
         {
            name: "Akwa Ibom",
            state_code: "AK",
         },
         {
            name: "Anambra",
            state_code: "AN",
         },
         {
            name: "Bauchi",
            state_code: "BA",
         },
         {
            name: "Bayelsa",
            state_code: "BY",
         },
         {
            name: "Rivers",
            state_code: "RI",
         },
         {
            name: "Benue",
            state_code: "BE",
         },
         {
            name: "Borno",
            state_code: "BO",
         },
         {
            name: "Cross River",
            state_code: "CR",
         },
         {
            name: "Delta",
            state_code: "DE",
         },
         {
            name: "Ebonyi",
            state_code: "EB",
         },
         {
            name: "Edo",
            state_code: "ED",
         },
         {
            name: "Ekiti",
            state_code: "EK",
         },
         {
            name: "Enugu",
            state_code: "EN",
         },
         {
            name: "Federal Capital Territory",
            state_code: "FC",
         },
         {
            name: "Gombe",
            state_code: "GO",
         },
         {
            name: "Imo",
            state_code: "IM",
         },
         {
            name: "Jigawa",
            state_code: "JI",
         },
         {
            name: "Kaduna",
            state_code: "KD",
         },
         {
            name: "Kano",
            state_code: "KN",
         },
         {
            name: "Katsina",
            state_code: "KT",
         },
         {
            name: "Kebbi",
            state_code: "KE",
         },
         {
            name: "Kogi",
            state_code: "KO",
         },
         {
            name: "Kwara",
            state_code: "KW",
         },
         {
            name: "Lagos",
            state_code: "LA",
         },
         {
            name: "Nasarawa",
            state_code: "NA",
         },
         {
            name: "Niger",
            state_code: "NI",
         },
         {
            name: "Ogun",
            state_code: "OG",
         },
         {
            name: "Ondo",
            state_code: "ON",
         },
         {
            name: "Osun",
            state_code: "OS",
         },
         {
            name: "Oyo",
            state_code: "OY",
         },
         {
            name: "Plateau",
            state_code: "PL",
         },
         {
            name: "Sokoto",
            state_code: "SO",
         },
         {
            name: "Taraba",
            state_code: "TA",
         },
         {
            name: "Yobe",
            state_code: "YO",
         },
         {
            name: "Zamfara",
            state_code: "ZA",
         },
      ],
   },
   {
      name: "Ghana",
      iso3: "GHA",
      states: [
         {
            name: "Ahafo",
            cities: ["Goaso", "Hwidiem", "Kenyasi", "Kukuom"],
         },
         {
            name: "Ashanti",
            cities: [
               "Abrepo Junction",
               "Abuakwa",
               "Adum",
               "Afrancho",
               "Agogo",
               "Agona",
               "Ahodwo",
               "Airport",
               "Akumadan",
               "Amakom",
               "Aputuogya",
               "Asafo",
               "Ash-Town",
               "Asokore Mampong",
               "Asokwa",
               "Asuofia",
               "Atonsu",
               "Ayeduasi",
               "Ayigya",
               "Bantama",
               "Barekese",
               "Bekwai",
               "Boadi",
               "Bomso",
               "Breman",
               "Brewery",
               "Buoho",
               "Buokrom Estate",
               "Daban",
               "Dakwadwom",
               "Deduako",
               "Denyame",
               "Effiduase",
               "Ejisu",
               "Ejura",
               "Emina",
               "Esreso",
               "Fawode",
               "Gyinyasi",
               "Jachie Pramso",
               "Kaasi",
               "KNUST",
               "Kodie",
               "Komfo Anokye",
               "Konongo",
               "Kotei",
               "Krofrom",
               "Kumasi",
               "Maakro",
               "Mampong",
               "Mankranso",
               "Manso Nkwanta",
               "Nkawie",
               "North Suntreso",
               "Nyinahin",
               "Obuasi",
               "Oforikrom",
               "Pankrono",
               "Santasi",
               "Sokoban",
               "South Suntreso",
               "Suame",
               "Tafo",
               "Takwa-Maakro",
               "Tanoso",
               "Tepa",
               "TUC",
            ],
         },
         {
            name: "Bono East",
            cities: [
               "Atebubu",
               "Buipe",
               "Jema",
               "Kintampo",
               "Kwame Danso",
               "Nkoranza",
               "Prang",
               "Techiman",
               "Wenchi",
               "Yeji",
            ],
         },
         {
            name: "Bono",
            cities: [
               "Banda Ahenkro",
               "Brekum",
               "Dormaa Ahenkro",
               "New Drobo",
               "Nsawkaw",
               "Sampa",
               "Sunyani",
            ],
         },
         {
            name: "Central",
            cities: [
               "Agona Swedru",
               "Amanfrom",
               "Anomabu",
               "Apam",
               "Bawjiase",
               "Breman Asikuma",
               "Budumburam",
               "Cape Coast",
               "Domeabra",
               "Elmina",
               "Foso",
               "Kasoa Ofaakor",
               "Kasoa Zongo",
               "Liberia Camp",
               "Mankessim",
               "Millennium City",
               "Mumford",
               "Nyakrom",
               "Nyananor",
               "Nyanyano",
               "Oduponkpehe",
               "Opeikuma",
               "Pentecost Seminary",
               "Saltpond",
               "Winneba",
            ],
         },
         {
            name: "Eastern",
            cities: [
               "Abetifi",
               "Abompe",
               "Aburi",
               "Adeiso",
               "Adjeiikrom",
               "Adukrom",
               "Ahwerease",
               "Akim Oda",
               "Akim Swedru",
               "Akosombo",
               "Akropong",
               "Akwatia",
               "Akyem Hemang",
               "Amanokrom",
               "Apirede",
               "Asamankese",
               "Asesewa",
               "Atimpoku",
               "Ayiensu",
               "Begoro",
               "Bepoase",
               "Bisibom",
               "Bososo",
               "Brekusu",
               "Coaltar",
               "Dawu",
               "Dedesoworako",
               "Donkorkrom",
               "Dwenase",
               "Gyankama",
               "Juaso",
               "Kade",
               "Kibi",
               "Kitase",
               "Koforidua",
               "Kwabeng",
               "Mamfe",
               "Mampong",
               "Mpraeso",
               "New Abirem",
               "Nkawkaw",
               "Nsawam",
               "Nsutam",
               "Obooho",
               "Obosomase",
               "Odumase Krobo",
               "Ofoase",
               "Osino",
               "Peduase",
               "Saaman",
               "Somanya",
               "Summer",
               "Suhum",
               "Tease",
               "Tutu",
            ],
         },
         {
            name: "Greater Accra",
            cities: [
               "Abelemkpe",
               "Ablekuma",
               "Abokobi",
               "Abossey Okai",
               "Accra Newtown",
               "Achimota",
               "Adabraka",
               "Adenta",
               "Afienya",
               "Agbogba",
               "Airport",
               "Amasaman",
               "Ashiaman",
               "Ashongman",
               "Aslyum Down",
               "Baatsona - Spintex",
               "Bortianor",
               "Cantonment",
               "Dansoman",
               "Darkuman",
               "Dawhenya",
               "Dodowa",
               "Dome",
               "Dzorwulu",
               "East Legon",
               "Gbawe",
               "Haatso",
               "James Town",
               "Kanda",
               "Kaneshie",
               "Kasoa",
               "Kissieman",
               "Kokrobite",
               "Korle Bu",
               "Kpone",
               "Kwabenya",
               "Kwashieman",
               "Labadi",
               "Labone",
               "Lapaz",
               "Lartebiokorshie",
               "Lashibi",
               "Legon",
               "Madina",
               "Makola",
               "Mallam",
               "McCarthy Hill",
               "Michel Camp",
               "Nima",
               "Nungua",
               "Oblogo Mallam",
               "Odoponkpehe",
               "Odorkor",
               "Osu",
               "Oyarifa",
               "Patang",
               "Prampram",
               "Ridge",
               "Roman Ridge",
               "Sakumono",
               "Santa Maria",
               "Sowutuom",
               "Taifa",
               "Tema",
               "Tema New Town",
               "Tesano",
               "Teshie",
               "Tetegu",
               "Tieman",
               "Tudu",
               "Weija",
               "Westhills",
            ],
         },
         {
            name: "North East",
            cities: ["Chereponi", "Gambaga", "Nalerigu", "Walewale"],
         },
         {
            name: "Nothern",
            cities: ["Bimbila", "Gushiegu", "Kpandae", "Salaga", "Tamale", "Yendi"],
         },
         {
            name: "Oti",
            cities: ["Chinderi", "Jasikan", "Kadjebi", "Kete Krachi", "Kpassa", "Nkonya"],
         },
         {
            name: "Savannah",
            cities: ["Bole", "Buipe", "Damango", "Salaga", "Sawla", "Tolon"],
         },
         {
            name: "Upper East",
            cities: ["Bawku", "Bolgatanga", "Bongo", "Navrongo", "Paga", "Tongo"],
         },
         {
            name: "Upper West",
            cities: [
               "Funsi",
               "Gwollu",
               "Issa",
               "Jirapa",
               "Kaleo",
               "Lambussie",
               "Lawra",
               "Nadowli",
               "Nandom",
               "Tumu",
               "Wa",
               "Wechiau",
            ],
         },
         {
            name: "Volta",
            cities: [
               "Adaklu Waya",
               "Adidome",
               "Aflao",
               "Akatsi",
               "Ave Dakpa",
               "Ho.",
               "Hohoe",
               "Keta",
               "Kpando",
               "Kpetoe",
               "Kpeve",
               "Sogakope",
            ],
         },
         {
            name: "Western North",
            cities: ["Akontombra", "Bibiani", "Dadieso", "Enchi", "Juaboso", "Sewhi Anhwiaso"],
         },
         {
            name: "Western",
            cities: [
               "Aboso",
               "Asankragua",
               "Axim",
               "Bogoso",
               "Elubo",
               "Half Assini",
               "Inchaban",
               "Prestea",
               "Samreboi",
               "Sekondi",
               "Shama",
               "Takoradi",
               "Tarkwa",
            ],
         },
      ],
   },
   {
      name: "Kenya",
      iso3: "KEN",
      states: [
         {
            name: "Baringo County",
            capital: "Kabarnet",
            code: 30,
            sub_counties: [
               "Baringo central",
               "Baringo north",
               "Baringo south",
               "Eldama ravine",
               "Mogotio",
               "Tiaty",
            ],
         },
         {
            name: "Bomet County",
            capital: "Bomet",
            code: 36,
            sub_counties: ["Bomet central", "Bomet east", "Chepalungu", "Konoin", "Sotik"],
         },
         {
            name: "Bungoma County",
            capital: "Bungoma",
            code: 39,
            sub_counties: [
               "Bumula",
               "Kabuchai",
               "Kanduyi",
               "Kimilil",
               "Mt Elgon",
               "Sirisia",
               "Tongaren",
               "Webuye east",
               "Webuye west",
            ],
         },
         {
            name: "Busia County",
            capital: "Busia",
            code: 40,
            sub_counties: ["Budalangi", "Butula", "Funyula", "Nambele", "Teso North", "Teso South"],
         },
         {
            name: "Elgeyo-Marakwet County",
            capital: "Iten",
            code: 28,
            sub_counties: ["Keiyo north", "Keiyo south", "Marakwet east", "Marakwet west"],
         },
         {
            name: "Embu County",
            capital: "Embu",
            code: 14,
            sub_counties: ["Manyatta", "Mbeere north", "Mbeere south", "Runyenjes"],
         },
         {
            name: "Garissa County",
            capital: "Garissa",
            code: 7,
            sub_counties: ["Daadab", "Fafi", "Garissa", "Hulugho", "Ijara", "Lagdera balambala"],
         },
         {
            name: "Homa Bay County",
            capital: "Homa Bay",
            code: 43,
            sub_counties: [
               "Homabay town",
               "Kabondo",
               "Karachwonyo",
               "Kasipul",
               "Mbita",
               "Ndhiwa",
               "Rangwe",
               "Suba",
            ],
         },
         {
            name: "Isiolo County",
            capital: "Isiolo",
            code: 11,
            sub_counties: ["Isiolo", "Garba tula", "Merit"],
         },
         {
            name: "Kajiado County",
            code: 34,
            sub_counties: [
               "Isinya.",
               "Kajiado Central.",
               "Kajiado North.",
               "Loitokitok.",
               "Mashuuru.",
            ],
         },
         {
            name: "Kakamega County",
            capital: "Kakamega",
            code: 37,
            sub_counties: [
               "Butere",
               "Kakamega central",
               "Kakamega east",
               "Kakamega north",
               "Kakamega south",
               "Khwisero",
               "Lugari",
               "Lukuyani",
               "Lurambi",
               "Matete",
               "Mumias",
               "Mutungu",
               "Navakholo",
            ],
         },
         {
            name: "Kericho County",
            capital: "Kericho",
            code: 35,
            sub_counties: [
               "Ainamoi",
               "Belgut",
               "Bureti",
               "Kipkelion east",
               "Kipkelion west",
               "Soin sigowet",
            ],
         },
         {
            name: "Kiambu County",
            capital: "Kiambu",
            code: 22,
            sub_counties: [
               "Gatundu north",
               "Gatundu south",
               "Githunguri",
               "Juja",
               "Kabete",
               "Kiambaa",
               "Kiambu",
               "Kikuyu",
               "Limuru",
               "Ruiru",
               "Thika town",
               "lari",
            ],
         },
         {
            name: "Kilifi County",
            capital: "Kilifi",
            code: 3,
            sub_counties: [
               "Genzw",
               "Kaloleni",
               "Kilifi north",
               "Kilifi south",
               "Magarini",
               "Malindi",
               "Rabai",
            ],
         },
         {
            name: "Kirinyaga County",
            capital: "Kerugoya/Kutus",
            code: 20,
            sub_counties: [
               "Kirinyaga central",
               "Kirinyaga east",
               "Kirinyaga west",
               "Mwea east",
               "Mwea west",
            ],
         },
         {
            name: "Kisii County",
            capital: "Kisii",
            code: 45,
            sub_counties: [],
         },
         {
            name: "Kisumu County",
            capital: "Kisumu",
            code: 42,
            sub_counties: [
               "Kisumu central",
               "Kisumu east ",
               "Kisumu west",
               "Mohoroni",
               "Nyakach",
               "Nyando",
               "Seme",
            ],
         },
         {
            name: "Kitui County",
            capital: "Kitui",
            code: 15,
            sub_counties: [
               "Ikutha",
               "Katulani",
               "Kisasi",
               "Kitui central",
               "Kitui west ",
               "Lower yatta",
               "Matiyani",
               "Migwani",
               "Mutitu",
               "Mutomo",
               "Muumonikyusu",
               "Mwingi central",
               "Mwingi east",
               "Nzambani",
               "Tseikuru",
            ],
         },
         {
            name: "Kwale County",
            capital: "Kwale",
            code: 2,
            sub_counties: ["Kinango", "Lungalunga", "Msambweni", "Mutuga"],
         },
         {
            name: "Laikipia County",
            capital: "Rumuruti",
            code: 31,
            sub_counties: [
               "Laikipia central",
               "Laikipia east",
               "Laikipia north",
               "Laikipia west ",
               "Nyahururu",
            ],
         },
         {
            name: "Lamu County",
            capital: "Lamu",
            code: 5,
            sub_counties: ["Lamu East", "Lamu West"],
         },
         {
            name: "Machakos County",
            capital: "Machakos",
            code: 16,
            sub_counties: [
               "Kathiani",
               "Machakos town",
               "Masinga",
               "Matungulu",
               "Mavoko",
               "Mwala",
               "Yatta",
            ],
         },
         {
            name: "Makueni County",
            capital: "Wote",
            code: 17,
            sub_counties: ["Kaiti", "Kibwei west", "Kibwezi east", "Kilome", "Makueni", "Mbooni"],
         },
         {
            name: "Mandera County",
            capital: "Mandera",
            code: 9,
            sub_counties: [
               "Banissa",
               "Lafey",
               "Mandera East",
               "Mandera North",
               "Mandera South",
               "Mandera West",
            ],
         },
         {
            name: "Marsabit County",
            capital: "Marsabit",
            code: 10,
            sub_counties: ["Laisamis", "Moyale", "North hor", "Saku"],
         },
         {
            name: "Meru County",
            capital: "Meru",
            code: 12,
            sub_counties: [
               "Buuri",
               "Igembe central",
               "Igembe north",
               "Igembe south",
               "Imenti central",
               "Imenti north",
               "Imenti south",
               "Tigania east",
               "Tigania west",
            ],
         },
         {
            name: "Migori County",
            capital: "Migori",
            code: 44,
            sub_counties: [
               "Awendo",
               "Kuria east",
               "Kuria west",
               "Mabera",
               "Ntimaru",
               "Rongo",
               "Suna east",
               "Suna west",
               "Uriri",
            ],
         },
         {
            name: "Mombasa County",
            capital: "Mombasa City",
            code: 1,
            sub_counties: ["Changamwe", "Jomvu", "Kisauni", "Likoni", "Mvita", "Nyali"],
         },
         {
            name: "Murang'a County",
            capital: "Murang'a",
            code: 21,
            sub_counties: [
               "Gatanga",
               "Kahuro",
               "Kandara",
               "Kangema",
               "Kigumo",
               "Kiharu",
               "Mathioya",
               "Murang’a south",
            ],
         },
         {
            name: "Nairobi County",
            capital: "Nairobi City",
            code: 47,
            sub_counties: [
               "Dagoretti North Sub County",
               "Dagoretti South Sub County ",
               "Embakasi Central Sub Count",
               "Embakasi East Sub County",
               "Embakasi North Sub County ",
               "Embakasi South Sub County",
               "Embakasi West Sub County",
               "Kamukunji Sub County",
               "Kasarani Sub County ",
               "Kibra Sub County ",
               "Lang'ata Sub County ",
               "Makadara Sub County",
               "Mathare Sub County ",
               "Roysambu Sub County ",
               "Ruaraka Sub County ",
               "Starehe Sub County ",
               "Westlands Sub County ",
            ],
         },
         {
            name: "Nakuru County",
            capital: "Nakuru",
            code: 32,
            sub_counties: [
               "Bahati",
               "Gilgil",
               "Kuresoi north",
               "Kuresoi south",
               "Molo",
               "Naivasha",
               "Nakuru town east",
               "Nakuru town west",
               "Njoro",
               "Rongai",
               "Subukia",
            ],
         },
         {
            name: "Nandi County",
            capital: "Kapsabet",
            code: 29,
            sub_counties: ["Aldai", "Chesumei", "Emgwen", "Mosop", "Namdi hills", "Tindiret"],
         },
         {
            name: "Narok County",
            capital: "Narok",
            code: 33,
            sub_counties: [
               "Narok east",
               "Narok north",
               "Narok south",
               "Narok west",
               "Transmara east",
               "Transmara west",
            ],
         },
         {
            name: "Nyamira County",
            capital: "Nyamira",
            code: 46,
            sub_counties: ["Borabu", "Manga", "Masaba north", "Nyamira north", "Nyamira south"],
         },
         {
            name: "Nyandarua County",
            capital: "Ol Kalou",
            code: 18,
            sub_counties: ["Kinangop", "Kipipiri", "Ndaragwa", "Ol Kalou", "Ol joro orok"],
         },
         {
            name: "Nyeri County",
            capital: "Nyeri",
            code: 19,
            sub_counties: [
               "Kieni east",
               "Kieni west",
               "Mathira east",
               "Mathira west",
               "Mkurweni",
               "Nyeri town",
               "Othaya",
               "Tetu",
            ],
         },
         {
            name: "Samburu County",
            capital: "Maralal",
            code: 25,
            sub_counties: ["Samburu east", "Samburu north", "Samburu west"],
         },
         {
            name: "Siaya County",
            capital: "Siaya",
            code: 41,
            sub_counties: ["Alego usonga", "Bondo", "Gem", "Rarieda", "Ugenya", "Unguja"],
         },
         {
            name: "Taita-Taveta County",
            capital: "Voi",
            code: 6,
            sub_counties: ["Mwatate", "Taveta", "Voi", "Wundanyi"],
         },
         {
            name: "Tana River County",
            capital: "Hola",
            code: 4,
            sub_counties: ["Bura", "Galole", "Garsen"],
         },
         {
            name: "Tharaka-Nithi County",
            capital: "Chuka",
            code: 13,
            sub_counties: [
               "Chuka",
               "Igambangobe",
               "Maara",
               "Muthambi",
               "Tharak north",
               "Tharaka south",
            ],
         },
         {
            name: "Trans-Nzoia County",
            capital: "Kitale",
            code: 26,
            sub_counties: ["Cherangany", "Endebess", "Kiminini", "Kwanza", "Saboti"],
         },
         {
            name: "Turkana County",
            capital: "Lodwar",
            code: 23,
            sub_counties: [
               "Loima",
               "Turkana central",
               "Turkana east",
               "Turkana north",
               "Turkana south",
            ],
         },
         {
            name: "Uasin Gishu County",
            capital: "Eldoret",
            code: 27,
            sub_counties: ["Ainabkoi", "Kapseret", "Kesses", "Moiben", "Soy", "Turbo"],
         },
         {
            name: "Vihiga County",
            capital: "Vihiga",
            code: 38,
            sub_counties: ["Emuhaya", "Hamisi", "Luanda", "Sabatia", "vihiga"],
         },
         {
            name: "Wajir County",
            capital: "Wajir",
            code: 8,
            sub_counties: [
               "Eldas",
               "Tarbaj",
               "Wajir East",
               "Wajir North",
               "Wajir South",
               "Wajir West",
            ],
         },
         {
            name: "West Pokot County",
            capital: "Kapenguria",
            code: 24,
            sub_counties: ["Central Pokot", "North Pokot", "Pokot South", "West Pokot"],
         },
      ],
   },
];
