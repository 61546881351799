/* eslint-disable @next/next/no-img-element */
import { FC } from "react";
import Image from "next/image";
import router from "next/router";
import { Button } from "components/button";
import { Ratings } from "components/ratings";
import { Arrow } from "container/study-abroad/study-abroad.style";
import { capitalize } from "utils/capitalize";
import { formatCurrency } from "utils/generic-utils";
import { kFormatter } from "utils/number-formatter";
import {
   SchoolCardContainer,
   SchoolImageContainer,
   SchoolDetails,
   SchoolCardFooter,
} from "./landing-page.style";

interface ISchoolCard {
   data: {
      address: string;
      logo: string;
      type: string;
      name: string;
      isVerified: boolean;
      slug: string;
      discount: string;
      state: string;
      country: string;
      category: string;
      schoolFeeMinAmount: number;
      schoolFeeMaxAmount: number;
      rating: number;
      banner: string;
      likes: number;
      views: number;
   };
   hasDiscount?: string;
}

export const SchoolCard: FC<ISchoolCard> = ({ data, hasDiscount }: ISchoolCard) => {
   const {
      name,
      address,
      banner,
      isVerified,
      slug,
      state,
      country,
      category,
      schoolFeeMinAmount,
      schoolFeeMaxAmount,
      rating,
      likes,
      views,
      discount,
      type,
   } = data;

   return (
      <SchoolCardContainer onClick={() => router.push(`/school/${slug}`)}>
         <>
            <SchoolImageContainer>
               {hasDiscount && +discount > 0 && <span className="discount">Up to {discount}%</span>}
               <div style={{ overflow: "hidden", position: "relative" }}>
                  <Image
                     src={
                        banner?.includes("http")
                           ? banner
                           : "https://www.edusko.com/sites/default/files/styles/four_grid/public/schools/banners/gec_1.png?itok=1nDjKpOl"
                     }
                     alt="school"
                     layout="responsive"
                     objectFit="cover"
                     objectPosition="top"
                     width={202}
                     height={195}
                     placeholder="blur"
                     blurDataURL="/images/blur2.png"
                  />
               </div>

               <p className="school-type">{capitalize(category?.replace(/\_/gi, " "))}</p>
               <div className="analytics">
                  <div className="flex-analytics">
                     <img src="/images/Show.png" alt="show-icon" />
                     <p>{kFormatter(views)}</p>
                  </div>
                  <p>|</p>
                  <div className="flex-analytics">
                     <img src="/images/love.png" alt="love-icon" />
                     <p>{kFormatter(likes)}</p>
                  </div>
               </div>
            </SchoolImageContainer>
            <SchoolDetails>
               <div className="school-name-check">
                  <h5>{capitalize(type?.replace(/\_/gi, " "))}</h5>
                  {isVerified && (
                     <span className="verified-flag">
                        <img src="/images/check.png" alt="check-icon" />
                     </span>
                  )}
               </div>
               {name && (
                  <h4>
                     {`${name?.substring(0, 35)} `} {name?.length > 35 && "..."}
                  </h4>
               )}
               {address && (
                  <p className="school-info">
                     {`${address?.substring(0, 50)}`} {address?.length > 50 && "..."}
                  </p>
               )}
               <p className="school-info">
                  {state},{country}
               </p>
               {/* <p className="school-info type">{type?.replace(/\_/gi, " ")}</p> */}
               {schoolFeeMaxAmount && (
                  <p className="school-info price">
                     {formatCurrency(schoolFeeMinAmount, "NGN")} -{" "}
                     {formatCurrency(schoolFeeMaxAmount, "NGN")}
                  </p>
               )}
               <div className="rating-area">
                  <p>{rating}</p>
                  <Ratings readonly={true} value={rating * 20} />
               </div>

               <SchoolCardFooter>
                  <Button variant="link" size="small">
                     <span style={{ display: "flex", alignItems: "center" }}>
                        Explore
                        <Arrow style={{ marginLeft: "5px" }} />
                     </span>
                  </Button>
               </SchoolCardFooter>
            </SchoolDetails>
         </>
      </SchoolCardContainer>
   );
};
