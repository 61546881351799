import React from "react";
import { TooltipContainer, TooltipContent, TooltipTrigger } from "styles/text-heading-one";

const TriangleTooltip = ({ children, text }) => {
   return (
      <TooltipContainer>
         <TooltipTrigger>{children}</TooltipTrigger>
         <TooltipContent>{text}</TooltipContent>
      </TooltipContainer>
   );
};

export default TriangleTooltip;
