import { IAddNews } from "pages/admin/news/add";
import { Http, API } from "services/http/http";
import { paramsObjectToQueryString } from "utils/generic-utils";

export const fetchNews = (payload: Record<string, any>) =>
   API({
      method: "GET",
      url: `/cms/news/${paramsObjectToQueryString(payload)}`,
   });
export const fetchAllNews = () => Http.get(`/cms/news?page=1&limit=none`);
export const createNews = (payload: IAddNews) => Http.post("/cms/news", payload);
export const updateNews = (id: string, payload: IAddNews) => Http.put(`/cms/news/${id}`, payload);
export const deleteNews = (id: string) => Http.delete(`/cms/news/${id}`);
export const fetchSingleNews = (token, { queryKey }) =>
   Http.get(`/cms/news/${queryKey[1]}`, {
      headers: {
         authorization: token,
      },
   });
export const searchNews = (val: string) => Http.get(`/cms/news?search=${val}`);
