/* eslint-disable @next/next/no-img-element */
import { FaUserCircle } from "react-icons/fa";
import Slider, { Settings } from "react-slick";
import { MainContainer } from "styles/index/index.style";
import { TestimonialCardContainer, TestimonialCardsContainer } from "./landing-page.style";

const settings: Settings = {
   dots: false,
   autoplay: true,
   slidesToShow: 4,
   speed: 1000,
   autoplaySpeed: 4000,
   arrows: true,
   initialSlide: 0,
   infinite: true,
   responsive: [
      {
         breakpoint: 2000,
         settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: false,
            centerMode: true,
            centerPadding: "40",
            initialSlide: 3,
         },
      },
      {
         breakpoint: 1500,
         settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
         },
      },
      {
         breakpoint: 1000,
         settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: true,
         },
      },
      {
         breakpoint: 500 || 400 || 300,
         settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
         },
      },
   ],
};

export const TestimonialCards = ({ testimonials }) => {
   return (
      <TestimonialCardsContainer>
         <MainContainer>
            <h3>Testimonials From Our Parents</h3>
            <Slider draggable accessibility {...settings}>
               {[
                  testimonials?.map((t) => (
                     <TestimonialCard key={t} text={t.content} name={t.name} />
                  )),
               ]}
            </Slider>
         </MainContainer>
      </TestimonialCardsContainer>
   );
};

export const TestimonialCard = ({ text, name }: { text: string; name: string }) => {
   return (
      <TestimonialCardContainer>
         <p>{text}</p>
         <div className="img-container">
            <FaUserCircle size={23} />
            <span>{name}</span>
         </div>
      </TestimonialCardContainer>
   );
};
