import { IAddVideo } from "pages/admin/videos/add";
import { Http, API } from "services/http/http";
import { paramsObjectToQueryString } from "utils/generic-utils";

export const fetchVideos = (payload: Record<string, any>) =>
   API({
      method: "GET",
      url: `/cms/video/${paramsObjectToQueryString(payload)}`,
   });
export const fetchAllVideos = () => Http.get(`/cms/video?page=1&limit=none`);
export const createVideo = (payload: IAddVideo) => Http.post("/cms/video", payload);
export const updateVideo = (id: string, payload: IAddVideo) =>
   Http.put(`/cms/video/${id}`, payload);
export const deleteVideo = (id: string) => Http.delete(`/cms/video/${id}`);
export const fetchSingleVideo = (token, { queryKey }) =>
   Http.get(`/cms/video/${queryKey[1]}`, {
      headers: {
         authorization: token,
      },
   });
export const searchVideos = (val: string) => Http.get(`/cms/video?search=${val}`);
