import Slider, { Settings } from "react-slick";
import { BlogCard } from "components/blog-card";
import { MainContainer, NonBannerContainer } from "styles/index/index.style";
import { TextHeadingOne } from "styles/text-heading-one";
import { SchoolCards, BlogPostContainer } from "./landing-page.style";

export const BlogPosts = ({ blogData }) => {
   const settings: Settings = {
      dots: false,
      autoplay: true,
      slidesToShow: 4,
      speed: 1000,
      autoplaySpeed: 4000,
      arrows: true,
      initialSlide: 0,
      infinite: true,
      responsive: [
         {
            breakpoint: 1000,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               arrows: false,
               centerMode: true,
               centerPadding: "40",
               initialSlide: 3,
            },
         },
         {
            breakpoint: 1500,
            settings: {
               slidesToShow: 4,
               slidesToScroll: 1,
               arrows: true,
            },
         },
      ],
   };

   return (
      <NonBannerContainer>
         <MainContainer>
            <BlogPostContainer>
               <TextHeadingOne>Read out Interesting and exicting Blogs</TextHeadingOne>
               <SchoolCards>
                  <Slider draggable {...settings} className="card-slider">
                     {blogData?.map(({ imageUrl, summary, title, slug }, index) => (
                        <BlogCard
                           key={`${title}-${index}`}
                           imgSrc={imageUrl}
                           header={title}
                           body={summary}
                           isBtn
                           slug={slug}
                        />
                     ))}
                  </Slider>
               </SchoolCards>
            </BlogPostContainer>
         </MainContainer>
      </NonBannerContainer>
   );
};
