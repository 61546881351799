import { useEffect, useState } from "react";
import router from "next/router";
import { useQuery } from "react-query";
import { getSchoolsStats } from "services/school";
import { BannerCard, BannerCards } from "./landing-page.style";

export const AdvertBanners = () => {
   const [schoolStats, setSchoolStats] = useState<any>({});

   const { data } = useQuery<any>("summer-school-stats", () =>
      getSchoolsStats({ country: "nigeria" })
   );
   useEffect(() => {
      setSchoolStats(data?.data?.data);
   }, [data]);

   function routeWithSearchParams(param) {
      router.push({
         pathname: "/school/search",
         query: param,
      });
   }

   return (
      <BannerCards>
         <BannerCard
            url="/images/lagosschools.png"
            onClick={() => routeWithSearchParams({ search: "lagos" })}
         >
            <div>
               <h4>Schools in Lagos</h4>
               <h6>{`${schoolStats?.lagos} schools.`}</h6>
            </div>
         </BannerCard>
         <BannerCard
            url="/images/abujaschools.png"
            onClick={() => routeWithSearchParams({ search: "abuja" })}
         >
            <div>
               <h4>Schools in Abuja</h4>
               <h6>{`${schoolStats?.abuja} schools.`}</h6>
            </div>
         </BannerCard>
         <BannerCard
            url="/images/abroad.png"
            onClick={() => routeWithSearchParams({ search: "portharcourt" })}
         >
            <div>
               <h4>Schools in Port Harcourt</h4>
               <h6>{`${schoolStats?.studyAbroad} schools.`}</h6>
            </div>
         </BannerCard>
         <BannerCard url="/images/camps.png" onClick={() => router.push("/study-abroad")}>
            <div>
               <h4>Other Cities</h4>
               <h6>{`${schoolStats?.summerSchool} schools.`}</h6>
            </div>
         </BannerCard>
      </BannerCards>
   );
};
