/* eslint-disable import/order */
import { Button } from "components/button";
import { Arrow } from "container/study-abroad/study-abroad.style";
import Image from "next/image";
import { useRouter } from "next/router";
import { BlogCardBody, BlogCardContainer, BlogCardFooter } from "./blog-card.style";

interface Props {
   imgSrc?: string;
   header: string;
   body: string;
   isBtn?: boolean;
   readingTime?: string;
   slug?: string;
   category?: string;
}

export const BlogCard: React.FC<Props> = ({
   imgSrc,
   header,
   body,
   isBtn,
   readingTime,
   slug,
   category,
}) => {
   const router = useRouter();
   // let stripedHtml = posts?.content?.replace(/<[^>]+>/g, "");
   // const pageDescriptionMeta = stripedHtml?.split(".")[0];

   const handleSingleBlog = () => {
      router.push(`/blog/${slug}`);
   };

   return (
      <BlogCardContainer href={`/blog/${slug}`}>
         <article>
            {imgSrc && (
               <div
                  style={{
                     height: "224px",
                     overflow: "hidden",
                     cursor: "pointer",
                     borderRadius: "8px",
                  }}
               >
                  <Image
                     src={imgSrc}
                     alt="school"
                     layout="responsive"
                     objectFit="cover"
                     width={202}
                     height={148}
                     placeholder="blur"
                     blurDataURL="/images/blur2.png"
                  />
               </div>
            )}
            <BlogCardBody>
               <div>
                  <h3 className="category">{category}</h3>
                  <h4 className="heading-four" style={{ cursor: isBtn ? "default" : "pointer" }}>
                     {header}
                  </h4>
                  <p className="body-main-text" dangerouslySetInnerHTML={{ __html: body }} />
               </div>
               <BlogCardFooter>
                  {isBtn ? (
                     <Button size="small" variant="new_outline" onClick={handleSingleBlog}>
                        <span style={{ display: "flex", alignItems: "center" }}>
                           Read Article
                           <Arrow style={{ marginLeft: "5px" }} />
                        </span>
                     </Button>
                  ) : (
                     <span className="duration">{readingTime}</span>
                  )}
               </BlogCardFooter>
            </BlogCardBody>
         </article>
      </BlogCardContainer>
   );
};
