/* eslint-disable @next/next/no-img-element */
import { FC } from "react";
import Image from "next/image";
import Link from "next/link";
import router from "next/router";
import { Button } from "components/button";
import { Ratings } from "components/ratings";
import { Arrow } from "container/study-abroad/study-abroad.style";
import { capitalize } from "utils/capitalize";
import { formatCurrency } from "utils/generic-utils";
import { kFormatter } from "utils/number-formatter";
import {
   SchoolImageContainer,
   SchoolDetails,
   SchoolCardFooter,
   NewSchoolCardContainer,
} from "./landing-page.style";

interface ISchoolCard {
   data: {
      address: string;
      logo: string;
      type: string;
      name: string;
      isVerified: boolean;
      slug: string;
      discount: string;
      state: string;
      country: string;
      category: string;
      schoolFeeMinAmount: number;
      schoolFeeMaxAmount: number;
      rating: number;
      banner: string;
      likes: number;
      views: number;
   };
}

export const DiscountSchoolCard: FC<ISchoolCard> = ({ data }: ISchoolCard) => {
   const {
      name,
      banner,
      isVerified,
      slug,
      state,
      country,
      category,
      schoolFeeMinAmount,
      schoolFeeMaxAmount,
      rating,
      likes,
      views,
      type,
      discount,
   } = data;

   return (
      <Link href={`/school/${slug}`} passHref>
         <a>
            <NewSchoolCardContainer onClick={() => router.push(`/school/${slug}`)}>
               <>
                  <SchoolImageContainer>
                     {+discount > 0 && <span className="discount">Up to {discount}%</span>}
                     <div style={{ overflow: "hidden", position: "relative" }}>
                        <Image
                           src={
                              banner?.includes("http")
                                 ? banner
                                 : "https://www.edusko.com/sites/default/files/styles/four_grid/public/schools/banners/gec_1.png?itok=1nDjKpOl"
                           }
                           alt="school"
                           layout="responsive"
                           objectFit="cover"
                           objectPosition="top"
                           width={202}
                           height={195}
                           placeholder="blur"
                           blurDataURL="/images/blur2.png"
                        />
                     </div>

                     <p className="school-type">{capitalize(category?.replace(/\_/gi, " "))}</p>
                     <div className="analytics">
                        <div className="flex-analytics">
                           <img src="/images/Show.png" alt="show-icon" />
                           <p>{kFormatter(views)}</p>
                        </div>
                        <p>|</p>
                        <div className="flex-analytics">
                           <img src="/images/love.png" alt="love-icon" />
                           <p>{kFormatter(likes)}</p>
                        </div>
                     </div>
                  </SchoolImageContainer>
                  <SchoolDetails>
                     <div className="school-name-check">
                        <h5>{capitalize(type?.replace(/\_/gi, " "))}</h5>
                        {isVerified && (
                           <span className="verified-flag">
                              <img src="/landing-page/new_checkicon.png" alt="check-icon" />
                           </span>
                        )}
                     </div>
                     {name && (
                        <h4>
                           {`${name?.substring(0, 20)} `} {name?.length > 20 && "..."}
                        </h4>
                     )}
                     {/* {address && (
                        <p className="school-info">
                           {`${address?.substring(0, 30)}`} {address?.length > 30 && "..."}
                        </p>
                     )} */}
                     <p className="school-info address">
                        {state},{country}
                     </p>
                     {/* <p className="school-info type">{type?.replace(/\_/gi, " ")}</p> */}
                     {schoolFeeMaxAmount && (
                        <p className="school-info price">
                           {formatCurrency(schoolFeeMinAmount, "NGN")} -{" "}
                           {formatCurrency(schoolFeeMaxAmount, "NGN")}/ annum
                        </p>
                     )}
                     <div className="rating-area">
                        <p>{rating}</p>
                        <Ratings readonly={true} value={rating * 20} />
                     </div>

                     <SchoolCardFooter>
                        <Button variant="new_outline" size="small">
                           <span style={{ display: "flex", alignItems: "center" }}>
                              Learn More
                              <Arrow style={{ marginLeft: "5px" }} />
                           </span>
                        </Button>
                     </SchoolCardFooter>
                  </SchoolDetails>
               </>
            </NewSchoolCardContainer>
         </a>
      </Link>
   );
};
