import ReactPlayer from "react-player/lazy";
import { useQuery } from "react-query";
import Slider, { Settings } from "react-slick";
import { fetchAllVideos } from "services/videos";
import { TextHeadingOne } from "styles/text-heading-one";
import { VideosDontLieContainer } from "./landing-page.style";

const settings: Settings = {
   dots: false,
   autoplay: true,
   slidesToShow: 4,
   speed: 1000,
   autoplaySpeed: 4000,
   initialSlide: 0,
   infinite: true,
   responsive: [
      {
         breakpoint: 2000,
         settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            centerMode: true,
            centerPadding: "40",
            initialSlide: 3,
         },
      },
      {
         breakpoint: 1500,
         settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
         },
      },
      {
         breakpoint: 1000,
         settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
         },
      },
      {
         breakpoint: 500 || 400 || 300,
         settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
         },
      },
   ],
};

export const VideosDontLie = () => {
   const { data, isLoading } = useQuery("videos", fetchAllVideos);

   return (
      <VideosDontLieContainer>
         <TextHeadingOne>Our Impact Story</TextHeadingOne>
         {data?.data?.data?.videos?.length ? (
            <Slider draggable {...settings}>
               {isLoading
                  ? "loading..."
                  : data?.data?.data?.videos?.map((video) => {
                       return (
                          <div className="video" key={video.url}>
                             <ReactPlayer controls width="100%" height="600px" url={video.url} />
                             {/* <p className="title">{video.title}</p> */}
                          </div>
                       );
                    })}
            </Slider>
         ) : (
            "No Videos Yet"
         )}
      </VideosDontLieContainer>
   );
};
