import Image from "next/image";
import Link from "next/link";
import { useQuery } from "react-query";
import { fetchAllNews } from "services/news";
import { MainContainer } from "styles/index/index.style";
import { TextHeadingOne } from "styles/text-heading-one";
import { EduskoInTheNewsContainer, NewsContainer, SingleNews } from "./landing-page.style";

export const EduskoInTheNews = () => {
   const { data }: any = useQuery([], fetchAllNews, {
      onSuccess: () => {},
   });

   return (
      <EduskoInTheNewsContainer>
         <MainContainer>
            <TextHeadingOne style={{ textAlign: "center" }}>As Seen On</TextHeadingOne>
            <NewsContainer>
               {data?.data?.data?.news?.map((el) => (
                  <SingleNews key={el.id}>
                     <Link passHref href={el.url}>
                        <a target="_blank">
                           <Image
                              src={el.icon}
                              alt={el.source}
                              layout="intrinsic"
                              width={190}
                              height={45}
                              className="news-image"
                           />
                        </a>
                     </Link>
                  </SingleNews>
               ))}
            </NewsContainer>
         </MainContainer>
      </EduskoInTheNewsContainer>
   );
};
